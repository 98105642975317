import { useEffect,useState } from "react";
import {useNavigate } from "react-router-dom";
import { GetRandom, GetRanking } from "../../firebase/GetData";

export default function ChallengeRull(){
    const [cnt,setCnt]=useState(false)
    const [data,setData]=useState([])
    const navigate = useNavigate()

    const Start = async()=>{
        await GetRandom()
        const randomData = await GetRandom()
        navigate(`/challenge/${randomData.createdAt.seconds}/3/0`)
    }
    async function Load(){
        const com = await GetRanking()
        let i=1
        let list=[]
        com.forEach((doc)=>{
            list=[...list,<Rank i={i} data={doc.data()} key={i}/>]
            i++
        })
        setData(list)
        setCnt(true)
    }
    useEffect(()=>{
        Load()
    },[])
    return(
        <>
        <div style={{border:'solid 3px #A7DBD8',width:"90%",margin:'0 auto', borderRadius:'5px'}}>
            <h1 style={{fontFamily:'agroM',margin:'5% 5%'}}>룰 설명</h1>
            <div style={{width:'90%',margin:'0 auto',fontFamily:'agroL'}}>
                <p>뭐가 더 사람들이 많이 골랐을 지 맞추라!</p>
                <p>총 목숨은 ❤❤❤ 3개!</p>
                <button style={{backgroundColor:'#69D2E7'}}>황금밸런스</button>
                <p>↑↑↑ 이거는 무승부 같을 때 누르시오.</p>
            </div>
        </div>
        <button style={{margin:'10% 5%',textAlign:'center',width:'90%',fontFamily:'agroB',fontSize:'150%',backgroundColor:'#69D2E7',borderRadius:'5px'}} onClick={()=>{Start()}}>Start!</button>
        <div style={{border:'solid 3px #E0E4CC',width:"90%",margin:'0 auto', borderRadius:'5px'}}>
            <h1 style={{fontFamily:'agroM',margin:'5% 5%'}}>순위</h1>
            <div style={{width:'90%',margin:'0 auto',fontFamily:'agroL'}}>
                {cnt ? (data):("")}
            </div>
        </div>
        </>
    )
}

function Rank({i,data}){

    return(
        <div style={{marginBottom:'7%'}}>
        <span style={{fontSize:'150%'}}>{i}위:</span>
        <span style={{fontSize:'130%'}}> {data.name}</span>
        <span> {data.point}점</span>
        </div>
    )
}