import "./App.css";
import Navigation from "./components/Navigation1";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "./components/NotFoundPage";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { Make } from "./components/Make/Make";
import Login from "./firebase/login";
import Worry from "./components/Worry";
import Slider from "./components/Game/Slider2";
import Challenge from "./components/Challenge/Challenge";
import ChallengeRull from "./components/Challenge/ChallegeRull";
import SignUp from "./firebase/SignUp";
import WireFrame from "./components/Grid/WireFrame";
import RealTime from "./components/RealTime";
import GoBack from "./components/GoBack";
import Opinion from "./components/Opinion";
import Producer from "./components/Producer";
import { useMediaQuery } from 'react-responsive'
import BestTime from "./components/BestTime";
import BestSlider from "./components/Game/Slider3";
import WorrySlider from "./components/Game/WorrySlider";
function Reducer(currentState, action) {
  // 방송국의 기능(정보 저장, 정보 처리 행동)
  if (currentState === undefined) {
    return {
      Game:[],
      isMobile:'true'
    };
  }
  const newState = { ...currentState };
  if (action.type === "Plus") {
    newState.num++
  }
  if (action.type === "Com"){
    newState.isMobile = action.isMobile
    
  }
  return newState;
}
const store = createStore(Reducer);


function App({ authService }) {
  // const isTablet = useMediaQuery({ minDeviceWidth: 501})
  const isMobile = useMediaQuery({ maxWidth: 500 })
  
  return (
    <div className={isMobile ? (''):('Tablet')}>
      <Provider store={store}>
        <Navigation />
        {isMobile ? (<GoBack/>):('')}
        <Routes>
          <Route path="/" element={<WireFrame isMobile={isMobile}/>}></Route>
          <Route path="/login">
            <Route index element={<Login authService={authService} />}></Route> 
            <Route path="SignUp" element={<SignUp authService={authService} />}></Route> 
          </Route>
          <Route path="/BestGame" >
            <Route index element={<BestTime />}></Route>
            <Route path=':number1/:number2' element={<BestSlider isMobile={isMobile}/>}></Route>
            <Route path='-1' element={<NotFoundPage />}></Route>
          </Route>
          <Route path="/game" >
            <Route index element={<RealTime />}></Route>
            <Route path=':number1' element={<Slider isMobile={isMobile}/>}></Route>
            <Route path='*' element={<NotFoundPage />}></Route>
          </Route>
          <Route path="/Worry" >
            <Route index element={<Worry />}></Route>
            <Route path=':number1' element={<WorrySlider isMobile={isMobile}/>}></Route>
            <Route path='-1' element={<NotFoundPage />}></Route>
          </Route>
          <Route path="/make" element={<Make />}></Route>
          <Route path="/opinion" element={<Opinion />}></Route>
          <Route path="/producer" element={<Producer />}></Route>
          <Route path="/challenge">
            <Route index element={<ChallengeRull />}></Route>
            <Route path=':number1/:number2/:number3' element={<Challenge />}></Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Provider>
    </div>
  );
}

export default App;
