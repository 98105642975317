import {HeartFilled,HeartOutlined} from '@ant-design/icons';
import {useState,useEffect,useRef} from 'react'
import { GetOrder2, GetRandom, SetRanking } from "../../firebase/GetData";
import {useParams,useNavigate} from "react-router-dom";
import "../Game/Game.css"
import Card from '../Game/Card';
import { useMediaQuery } from 'react-responsive'
import { Swiper, SwiperSlide,useSwiperSlide,useSwiper } from "swiper/react";

export default function Challenge(){
    let {number1,number2,number3} = useParams()
    const navigate = useNavigate()
    const swiper = useSwiper();
    const myRef = useRef()
    const [data, setData] = useState({});
    const [isClicked, setIsClicked] = useState(false);
    const [heart, setHeart]= useState(3)
    const [point, setPoint]= useState(0)
    const [retry,setRetry]=useState()
    const isMobile = useMediaQuery({ maxWidth: 500 })

    async function boo(time) {
        let data1;
        const response = await GetOrder2(time,1,true)
        response.forEach((doc)=>{
            data1 = doc.data()
        })
        setData(data1);
        setHeart(number2)
        setPoint(number3)
        
    }
    function Click2(id,what){
        if (isClicked === false){
            setIsClicked(true);
            if (data.click1 > data.click2 && what === 1){
                setPoint((prev)=>prev*1 +1)
            }else if(data.click2 > data.click1 && what === 2){
                setPoint((prev)=>prev*1 +1)
            }else if(data.click1 === data.click2 && what ===3){
                setPoint((prev)=>prev*1 +1)
            }else{
                setHeart((prev)=>prev*1 -1)
            }
        }
        myRef.current.scrollIntoView()
        if(heart ===0){
            Next()
        }
    }

    const Next = async ()=>{
        
        if(heart >0){
            const randomData = await GetRandom()
            navigate(`/challenge/${randomData.createdAt.seconds}/${heart}/${point}`)
        }else{
            alert(`🎉게임 끝! 당신의 점수는 ${point}점 입니다!!`)
            setRetry(true)
            setIsClicked(false)
        }
    }
    
    const Retry = ()=>{
        navigate('/challenge')
    }
    useEffect(()=>{
        boo(number1);
        setIsClicked(false);
        setRetry(false)
    },[number1])
    return(
        <>
        <Swiper
        spaceBetween={50}
        slidesPerView={1}
        scrollbar={{ draggable: true }}
        pagination={{ clickable: true }}
        onReachEnd={() => {Next()}}
        breakpoints={{
            768: {
            slidesPerView: 1,
            },
        
        }}
        >
        <SwiperSlide >
            <div className="ground">
                {retry ? (<InfoCard point={point} isMobile={isMobile}/>):(<Card data={data} Click2={Click2} isClicked={isClicked}/>)}
            </div>  
            <div className='center'>
                <button style={{backgroundColor:'#69D2E7',fontFamily:'agroL' ,borderRadius:'5px',fontSize:'20px',marginTop:'1%'}} onClick={()=>{Click2(data.id,3)}}>황금밸런스</button>
                <div style={{margin:'3% 0 0 0' }}>
                    <span>{(heart > 0) ? (<HeartFilled style={{fontSize:'50px', color:'#CD3645'}}/>):(<HeartOutlined style={{fontSize:'50px'}}/>)}</span>
                    <span>{(heart > 1) ? (<HeartFilled style={{fontSize:'50px', color:'#CD3645'}}/>):(<HeartOutlined style={{fontSize:'50px'}}/>)}</span>
                    <span>{(heart > 2) ? (<HeartFilled style={{fontSize:'50px', color:'#CD3645'}}/>):(<HeartOutlined style={{fontSize:'50px'}}/>)}</span>
                </div>
                {retry ? (<button id="next" style={{backgroundColor:'#69D2E7',fontFamily:'agroL',width:'80%',fontSize:'30px',borderRadius:'5px',margin:'3% 0 5% 0'}} onClick={Retry}>Retry</button>) : (isClicked ? (<button id="next" style={{backgroundColor:'#69D2E7',fontFamily:'agroL',width:'80%',fontSize:'30px',borderRadius:'5px',margin:'3% 0 5% 0'}} onClick={Next}>Next</button>):(""))}
                <div  ref={myRef}>
                </div>
            </div>
        </SwiperSlide>
        {isClicked ? <SwiperSlide></SwiperSlide>:''}
        </Swiper>
        </>
        
    )
}

function InfoCard({point,isMobile}){
    const [name, setName] = useState('');
    const navigate = useNavigate()

    const onChange = (event) => {
        const {target: {name, value}} = event;
        if (name==='name') {
        setName(value)
        }
    }
    const onSubmit = async (event) => {
        event.preventDefault();
        try {
          await SetRanking(name,point)
          navigate('/')
        } catch(error) {
          console.log(error)
        }
      }
    return(
        <>
        <div className={isMobile ? ('cardM'):('cardT')}>
            <span style={{fontFamily:'agroM',fontSize:'250%',margin:'20% 0% 5% 5%'}}>총 점수는 {point}점!</span>
            <form onSubmit={onSubmit} style={{textAlign:'center'}}>
                <h1 style={{textAlign:'left',marginLeft:'5%',fontFamily:'agroM'}}>이름</h1>
                <input style={{ fontSize:'150%',border:'solid 2px #d1d1d1', width:'90%',margin:'5% 0',fontFamily:'agroM',borderRadius:'5px'}} name="name" type="name" placeholder="Name" maxLength={9} required value={name} onChange={onChange}/>
                <input type="submit" value={"점수 등록"} style={{textAlign:'center',width:'90%',backgroundColor:'#69D2E7',fontFamily:'agroM',fontSize:'150%',borderRadius:'5px'}}/>
            </form>
        </div>
        </>
    )
}
