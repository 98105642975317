import phoy from '../img/323.jpg'

export default function Producer(){

    return(
        <div style={{fontFamily:'agroL'}}>
            <img src={phoy} alt={"profile"} style={{width:'50%',margin:'0 25%'}}></img>
            <div style={{margin:'10% 0 0 0'}}>
                <h2 style={{margin:'5% 0 0 0', fontWeight:'bold'}}>📧 문의 이메일: <p style={{fontWeight:'normal'}}>gapple71113@gmail.com</p></h2>
                <h2 style={{margin:'5% 0 0 0', fontWeight:'bold'}}>🏆 기술 스택: <p style={{fontWeight:'normal'}}>HTML, CSS, REACT, FIREBASE</p></h2>
                <h2 style={{margin:'5% 0 0 0', fontWeight:'bold'}}>👨‍💻 제작자 정보: <p style={{fontWeight:'normal'}}>기계공학과 출신 웹 제작에 관심이 많은 학생</p></h2>
                <h2 style={{margin:'5% 0 0 0', fontWeight:'bold'}}>😙 좋아하는 것: <p style={{fontWeight:'normal'}}>알</p></h2>
            </div>
        </div>
    )
}