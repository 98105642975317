import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  FacebookAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithCustomToken,
  
} from "firebase/auth";

class AuthServcie {
  constructor() {
    this.auth = getAuth();
    this.googleProvider = new GoogleAuthProvider();
    this.githubProvider = new GithubAuthProvider();
    this.facebookProvider = new FacebookAuthProvider();
  }
  login(providerName) {
    const provider = this.getProvider(providerName);
    return signInWithPopup(this.auth, provider);
  }
  getProvider(providerName) {
    switch (providerName) {
      case "Google":
        return this.googleProvider;
      case "Github":
        return this.githubProvider;
      case "Facebook":
        return this.facebookProvider;
      default:
        return
    }
  }
  createUserWithEmailAndPassword(email, password){
     return createUserWithEmailAndPassword(this.auth, email, password);
  }
  signInWithEmailAndPassword(email, password){
    return signInWithEmailAndPassword(this.auth, email, password);
  }
  signInWithCustomToken(token){
    return signInWithCustomToken(token)
  }
}
const auth = getAuth();
export const signupEmail = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const loginEmail = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export default AuthServcie;
