import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { firestore } from "./firebase_config";
import {getAuth} from "firebase/auth"
require('firebase/auth')

export async function GetOrder2(number1,size=3,token=false,view=false) {
  const comment = firestore.collection("dobell2")
  
  if(view === 'Hot'){
    let response = await comment.orderBy('totalClick',"desc").limit(size).get();
    return response;
  }

  if(token === true){
    const time = new Date(number1*1000 + 1000)
    let response = await comment.where('createdAt','<=',time).orderBy("createdAt","desc").limit(size).get();
    return response;
  }
  if(number1 === undefined){
    let response = await comment.orderBy("createdAt","desc").limit(size).get();
    return response;
  }else{
    const time = new Date(number1*1000)
    let response = await comment.where('createdAt','<',time).orderBy("createdAt",'desc').limit(size).get();
    return response;
  }
}
export async function GetLast(){
  const comment = firestore.collection("dobell2")
  let response = await comment.orderBy('createdAt',"desc").limit(1).get()
  let data;
  response.forEach((doc)=>{
    data = doc.data()
  })
  return data
}
export async function GetRandom(){
  const comment = firestore.collection("dobell2")
  let data1 = await GetLast()
  let lastId = data1.id

  const randomId = Math.floor(Math.random()*lastId) + 1
  let response = await comment.where("id",'<=',randomId).orderBy("id",'desc').limit(1).get()
  let data;
  response.forEach((doc)=>{
    data = doc.data()
  })
  return data
}

export async function MakeData(common,makeSelect1, makeSelect2,userEmail) {
  const dobell = firestore.collection("dobell2");
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  let data2 = await GetLast()
  let newId = data2.id + 1
  const data = {
    click1: 0,
    click2: 0,
    totalClick:0,
    userEmail:userEmail,
    common:common,
    select1: makeSelect1,
    select2: makeSelect2,
    dislike:0,
    createdAt: serverTimestamp,
    id:newId,
    commentSize:0,
  };
  await dobell.doc(makeSelect1).set(data);
}
export async function GetWorry(number1,size=3,token=false,view=false) {
  const dobell = firestore.collection("worry");
  if(number1 === undefined){
    const response = await dobell.orderBy("createdAt","desc").limit(size).get();
    return response
  }else{
    const time = new Date(number1*1000+1000)
    let response = await dobell.where('createdAt','<',time).orderBy("createdAt",'desc').limit(size).get();
    return response;
  }
}
export async function MakeWorry(common,makeSelect1,makeSelect2,userEmail) {
  const dobell = firestore.collection("worry");
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  const data = {
    click1: 0,
    click2: 0,
    userEmail:userEmail,
    common:common,
    select1: makeSelect1,
    select2: makeSelect2,
    dislike:0,
    createdAt: serverTimestamp,
    commentSize:0,
  };
  await dobell.doc(makeSelect1).set(data)
}
export async function SetClick(data, what, collection="dobell2") {
  const dobell = firestore.collection(collection);
  console.log(collection)
  const increment = firebase.firestore.FieldValue.increment(1);
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  // const time2 = new Date(data.createdAt.seconds*1000)
  if (what === 1) {
    await dobell.doc(data.select1).update({ click1: increment, timestamp: serverTimestamp,totalClick:increment });
  } else {
    await dobell.doc(data.select1).update({ click2: increment, timestamp: serverTimestamp,totalClick:increment });
  }
}

export async function SetLike(id) {
  const dobell = firestore.collection("dobell");
  const increment = firebase.firestore.FieldValue.increment(1);
  await dobell
      .doc(String(id))
      .update({ like: increment });
  // window.location.reload();
}

export async function SetBadReport(data) {
  const dobell = firestore.collection("report");
  let time = data.createdAt.toDate();
  const report={
    userEmail:data.userEmail,
    common:data.common,
    select1: data.select1,
    select2: data.select2,
    createdAt: time,
  }
  await dobell.doc(data.select1).set(report);
}

export async function SetBadCommentReport(data) {
  const dobell = firestore.collection("commentReport");
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  await dobell.add({ name: data.name, word: data.word, id:data.id, timestamp: serverTimestamp })
}

export async function GetInit(number1,size=3,token=false,i) {
  const comment = firestore.collection("dobell2")
  let response;
  
  if(number1 === undefined){
    response = await comment.orderBy("createdAt","desc").limit(size).get();
  }else{
    const time = new Date(number1*1000)
    response = await comment.where('createdAt','<',time).orderBy("createdAt",'desc').limit(size).get();
  }
  response.forEach(async (doc)=>{
    let data= doc.data();
    await comment.doc(data.select1).update({totalClick:data.click1+data.click2})
  })
}
export async function GetComment(data,collection='comment3') {
  if (collection === 'worry'){
    collection = 'worryComment'
  }
  const comment = firestore
    .collection(collection)
    .doc(data.select1)
    .collection("cabinet");
  const response = await comment.orderBy("timestamp",'desc').get();
  return response;
}

export async function MakeComment(makename, makeSelect1, data,collection='comment3') {
  let collection1;
  if (collection === 'worry'){
     collection1 = 'worryComment'
  }
  const comment = firestore
    .collection(collection1)
    .doc(data.select1)
    .collection("cabinet");
  const dobell = firestore.collection(collection);
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  const increment = firebase.firestore.FieldValue.increment(1);
  const data1 = {
    name: makename,
    word: makeSelect1,
    timestamp: serverTimestamp,
  };
  await comment.add(data1, { merge: true });
  await dobell.doc(data.select1).update({commentSize:increment});
}

export async function MakeOpinion(makename, makeSelect1) {
  const opinion = firestore
    .collection("opinion")
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  const data = {
    name: makename,
    word: makeSelect1,
    timestamp: serverTimestamp,
  };
  await opinion.add(data, { merge: true });
}

export async function GetOpinion() {
  const opinion = firestore
    .collection("opinion")
  const response = await opinion.orderBy("timestamp",'desc').get();
  return response;
}

export async function SetRanking(name,point) {
  const ranking = firestore.collection("ranking")
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  const data = {
    name: name,
    point: point*1,
    createdAt: serverTimestamp,
  };
  await ranking.add(data, { merge: true });
}
export async function GetRanking(size = 5) {
  const ranking = firestore.collection("ranking")
  let response = await ranking.orderBy('point',"desc").limit(size).get();
  return response
}

export function get(email){
  getAuth()
  .getUserByEmail(email)
  .then((userRecord) => {
    console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
  })
  .catch((error) => {
    console.log('Error fetching user data:', error);
  });
}