import {ArrowLeftOutlined} from '@ant-design/icons';
import {useNavigate,useLocation} from "react-router-dom";
import "./GoBack.css"
import { useMediaQuery } from 'react-responsive'

export default function GoBack(){
    const navigate = useNavigate()
    const isMobile = useMediaQuery({ maxWidth: 500 })
    const juso = useLocation().pathname
    const jusoSecondSlash = juso.indexOf('/',1)

    if(juso.includes('challenge/')){
        return null
    }else if(juso === '/'   && isMobile){
        return null
    }

    const move = ()=>{
        if(juso.includes('game/')){
            return navigate('/game')
        }
        if(juso.includes('game')){
            return navigate('/')
        }
        if(juso === '/'){
            return navigate('/')
        }
        if(jusoSecondSlash===-1){
            return navigate('/')
        }else{
            return navigate(juso.slice(0,jusoSecondSlash))
        }
    }

    return(
        <>
        
            <div className={isMobile ? ('realM'):('realT')} onClick={move}>
                <ArrowLeftOutlined style={{ fontSize: '35px',color:'grey',position:'relative',top:'15%'}}/>
            </div>
       
        </>
    )
}