import { useEffect, useState } from "react"
import { GetOpinion } from "../firebase/GetData"
import { GameInputOpinion } from "./Make/Make"

export default function Opinion(){
    const [opinion1,setOpinion1]=useState([])
    const [cnt,setCnt]=useState(false)

    async function opinion(){
        const raw = await GetOpinion()
        let list1=[]
        let i =0
        raw.forEach((that,index)=>{
            let com = that.data();
            list1 =[...list1,<Tweet com={com} key={i}/>]
            i++
        })
        setOpinion1(list1)
        setCnt(true)
    }

    useEffect(()=>{
        opinion()
    },[])

    return(
        <>
        <div style={{fontFamily:'agroL',margin:'0 0 0 5%'}}>
            <p>* 관리자에게 의견을 남겨주세요!</p>
            <p>* 사이트 이용시 불편했던 점도 좋아요!🤗</p>
            <p>* 제작자 칭찬은 더 좋습니다!😃</p>
        </div>
        
        <div className="commentWindow">
            <div className="marker">
                <GameInputOpinion/>
            </div>
            <div className="board">{cnt && opinion1}</div>
        </div>

        </>
    )
}

function Tweet({com}){
    let childData = Time2Pretty(com.timestamp)
    
    return(
        <div>
            <div className='comment'>
                <div style={{display:'inline',fontWeight:'bold'}}>{com.name ? (com.name):("익명")}</div>
                <div style={{display:'inline'}}>
                    <span id='date'>{childData}</span>
                </div>
                <div>{com.word}</div>
            </div>
        </div>
    )
}

function Time2Pretty(times){
    if (times){
        let childData = times.toDate();
        childData = new Date(+childData + 3240 * 10000)
                    .toISOString()
                    .replace("T", " ")
                    .replace(/\..*/, "");
        return childData
    }
}