import {useEffect,useState} from 'react'
import { FakeCard } from './Grid/FakeCard';
import GridCard from './Grid/GridCard';
import { GetOrder2 } from "../firebase/GetData";

export default function BestTime({isMobile}){
    const [Box,setBox]=useState([])
    const [cnt,setCnt]=useState(false)
    const [cnt2,setCnt2]=useState(true)
    
    async function Load(){
        let list3 = []
        if (!cnt){
            let thing = await GetOrder2(undefined,12,false,'Hot')
            let i=0;
            thing.forEach((doc)=>{
            let com3 = doc.data()
            list3=[...list3, <GridCard data={com3} key={com3.createdAt.seconds} float={'left'} margin={'0 12px 3% 12px'} where={`/BestGame/${com3.createdAt.seconds}/${i}`} time={true}/>]
            i++
        })
            setBox(list3)
            setCnt(true)
        }
    }
    // const Add = async()=>{
    //     let FinalSeconds = Box[Box.length -1].props.data.createdAt.seconds
    //     if (FinalSeconds === 1656396426){
    //         //마지막 글 타임
    //         setCnt2(false)
    //         return alert("더이상 게시물이 없습니다!<@_@>")
    //     }
    //     let thing = await GetOrder2(FinalSeconds,12)
    //     let list4 = []
    //     thing.forEach((doc)=>{
    //         let com4 = doc.data()
    //         list4=[...list4,<GridCard data={com4} key={com4.createdAt.seconds} float={'left'} margin={'0 12px 3% 12px'} where={`/game/${com4.createdAt.seconds}`} time={true}/>]
    //     })
    //     setBox([...Box,...list4])
    // }
    useEffect(()=>{
        Load()
    },[])
    return(
        <>
            <span style={{fontFamily:'agroB',fontSize:'35px',marginLeft:'10%'}}>주간 핫 밸런스</span>
            <div>
            {cnt ? (
                <div style={{margin:'0 23px'}}>
                    {Box}
                </div>
            ):(<div style={{margin:'0 23px'}}>
                    <Fakes number={12}/>
                </div>)}
            </div>
            {/* {cnt2 && <button style={{width:'100%',fontFamily:'agroM',fontSize:'200%',backgroundColor:'#E0E4CC',borderRadius:'5px'}} onClick={Add}>더 보기 ↓↓</button>} */}
        </>
    )
}

function Fakes({number}){
    let cnt4 =false
    let list3 = []
    for(let i=0;i<number;i++){
        list3=[...list3,<FakeCard  margin={'0 12px 3% 12px'} key={i}/>]
    }
    cnt4=true
    return(
        <>
            {cnt4 && list3}
        </>
    )
}