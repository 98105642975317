import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthServcie from "./firebase/auth_service";
import { firebaseApp } from "./firebase/firebase_config";

const authService = new AuthServcie(firebaseApp);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App authService={authService} />
  </BrowserRouter>
);
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//         <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );
