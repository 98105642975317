import {useEffect,useState} from 'react'
import { FakeCard } from './Grid/FakeCard';
import GridCard from './Grid/GridCard';
import { GetWorry} from "../firebase/GetData";

export default function Worry(){
    const [Box,setBox]=useState([])
    const [cnt,setCnt]=useState(false)
    const [cnt2,setCnt2]=useState(true)
    const [ii,setII]=useState(0)
    
    async function Load(){
        let list3 = []
        if (!cnt){
            let thing = await GetWorry(undefined,12)
            thing.forEach((doc)=>{
            let com3 = doc.data()
            list3=[...list3, <GridCard data={com3} key={com3.createdAt.seconds} backGroundColor={'#F0DCDD'} margin={'0 12px 3% 12px'} where={`/Worry/${com3.createdAt.seconds}`} time={true}/>]
        })
            setBox(list3)
            setCnt(true)
        }
    }
    const Add = async()=>{
        let FinalSeconds = Box[Box.length -1].props.data.createdAt.seconds
        if (FinalSeconds === 1653096241){
            //마지막 글 타임
            setCnt2(false)
            return alert("더이상 게시물이 없습니다!<@_@>")
        }
        let thing = await GetWorry(FinalSeconds,12)
        let list4 = []
        thing.forEach((doc)=>{
            let com4 = doc.data()
            list4=[...list4,<GridCard data={com4} key={com4.createdAt.seconds} backGroundColor={'#F0DCDD'} margin={'0 12px 3% 12px'} where={`/Worry/${com4.createdAt.seconds}`} time={true}/>]
        })
        setBox([...Box,...list4])
    }

    useEffect(()=>{
        Load()
    },[])
    return(
        <>
            <span style={{fontFamily:'agroB',fontSize:'35px',marginLeft:'10%'}}>고민 게시판</span>
            <div>
            {cnt ? (
                <div style={{margin:'0 23px'}}>
                    {Box}
                </div>
            ):(<div style={{margin:'0 23px'}}>
                    <Fakes number={12}/>
                </div>)}
            </div>
            {cnt2 && <button style={{width:'100%',fontFamily:'agroM',fontSize:'200%',backgroundColor:'#E0E4CC',borderRadius:'5px'}} onClick={Add}>더 보기 ↓↓</button>}
        </>
    )
}

function Fakes({number}){
    let cnt4 =false
    let list3 = []
    for(let i=0;i<number;i++){
        list3=[...list3,<FakeCard  margin={'0 12px 3% 12px'} backGroundColor={'#F0DCDD'} key={i}/>]
    }
    cnt4=true
    return(
        <>
            {cnt4 && list3}
        </>
    )
}


