// import React, { useEffect } from "react";
import { useState } from 'react';
import "./login.css"
import {useNavigate } from "react-router-dom";
// import facebook from "../img/facebook.png"
// import naver from "../img/naver.png"
import google from "../img/google.png"
// import kakao from "../img/kakao.png"

const SignUp = ({ authService }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const navigate = useNavigate()
  const onChange = (event) => {
    const {target: {name, value}} = event;
    if (name==='email') {
      setEmail(value)
    } else if (name=== "password") {
      setPassword(value);
    } else if (name=== "passwordConfirm") {
        setPasswordConfirm(value);
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if (password === passwordConfirm){
        try {
          await authService.createUserWithEmailAndPassword(email, password);
          navigate('/')
        } catch(error) {
          console.log(error)
          if(error.code.includes('email-already-in-use')){
            alert('이미 사용중인 이메일입니다.')
          }else if(error.code.includes('weak-password')){
            alert('비밀번호는 6자리 이상으로 설정해야합니다.')
          }else{
            alert(error)
          }
        }
    } else{
        alert("비밀번호가 서로 다릅니다!")
    }
  }
  const onLogin = (event) => {
    const loginRoute = event.currentTarget.alt
    authService.login(loginRoute).then(console.log);
  };
  return (
    <>
    <div className="loginDiv">
      <h2 id="loginWord">회원가입</h2>
      <form onSubmit={onSubmit}>
        <input className="logId" name="email" type="email" placeholder="Email" required value={email} onChange={onChange}/>
        <input className="logId" name="password" type="password" placeholder="비밀번호 (6자리 이상)" required value={password} onChange={onChange}/>
        <input className="logId" name="passwordConfirm" type="password" placeholder="비밀번호 확인" required value={passwordConfirm} onChange={onChange}/>
        <input type="submit" value={"회원가입"} id="submitButton"/>
      </form>
      <hr style={{height:"3px",}}></hr>
    </div>
    <div id="signUpSNS">
      <h2 id="">SNS 회원가입</h2>
      {/* <img src={naver} alt="Naver" onClick={onLogin} className="LoginLogo"/>
      <img src={kakao} alt="Kakao" onClick={onLogin} className="LoginLogo"/> */}
      <img src={google} alt="Google" onClick={onLogin} className="LoginLogo"/>
      {/* <img src={facebook} alt="Facebook" onClick={onLogin} className="LoginLogo"/> */}
    </div>
    </>
  );
};

export default SignUp