import React from "react";
import { useState } from 'react';
import "./login.css"
import {useNavigate } from "react-router-dom";
import facebook from "../img/facebook.png"
// import naver from "../img/naver.png"
import google from "../img/google.png"
// import kakao from "../img/kakao.png"

const Login = ({ authService }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate()

  const onChange = (event) => {
    const {target: {name, value}} = event;
    if (name==='email') {
      setEmail(value)
    } else if (name=== "password") {
      setPassword(value);
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      await authService.signInWithEmailAndPassword(email, password);
      navigate('/')
    } catch(error) {
      console.log(error)
    }
  }
  const onLogin = (event) => {
    const loginRoute = event.currentTarget.alt
    try{
      authService.login(loginRoute).then(console.log);
      navigate('/')
    }catch(error){
      alert("소셜 로그인에 실패했습니다!")
    }
  };
  return (
    <>
    <div className="loginDiv">
      <h2 id="loginWord">로그인</h2>
      <form onSubmit={onSubmit}>
        <input className="logId" name="email" type="email" placeholder="Email" required value={email} onChange={onChange}/>
        <input className="logId" name="password" type="password" placeholder="password" required value={password} onChange={onChange}/>
        <input type="submit" value={"로그인"} id="submitButton"/>
      </form>
      <hr style={{height:"3px",}}></hr>
    </div>
    <div id="signUpSNS">
      <h2 id="">SNS 로그인</h2>
      {/* <img src={naver} alt="Naver" onClick={onLogin} className="LoginLogo"/> */}
      {/* <img src={kakao} alt="Kakao" onClick={onLogin} className="LoginLogo"/> */}
      <img src={google} alt="Google" onClick={onLogin} className="LoginLogo"/>
      <img src={facebook} alt="Facebook" onClick={onLogin} className="LoginLogo"/>
      <div id="emailSignUp" onClick={()=>navigate('./SignUp')}><div>이메일 간편 가입</div></div>
    </div>
    </>
  );
};

export default Login;
