import "./GridCard.css"
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
export function FakeCard({backGroundColor,margin}){

       return(
        <div style={{float:'left',margin:margin}}>
            <div className="GridCard" style={{backgroundColor:backGroundColor}}>
                <div className="Gridcommon"><Skeleton count={2} height="100%" width={70} highlightColor="black"/></div>
                <Skeleton count={4}/>
                <div id="Gridchoice">
                    <div className="GridSelect"><Skeleton count={1}/></div>
                    {/* <div className="Gridvs">vs</div> */}
                    <div className="GridSelect" ><Skeleton count={1}/></div>
                </div>
            </div>
            <div style={{height:'40px',width:'138px',textAlign:'center'}}>
                {/* <LikeFilled style={{ color: 'black', fontSize: '20px',marginLeft:'15%'}}/> */}
                <div style={{display:'inline'}}>
                    <span id="GridLike">👀 </span>
                    <span id="GridComment">💬 </span>
                    <span style={{fontFamily:'agroL'}}></span>
                </div>
            </div>
        </div>
    )
}

export default function FakeCards({color,isMobile}){
    return(
        <>
        <Swiper
            spaceBetween={100}
            slidesPerView={isMobile ? (3):(5)}
            scrollbar={{ draggable: true }}
            navigation
            pagination={{ clickable: true }}
            // breakpoints={{
            //     768: {
            //     slidesPerView: 7,
            //     },
            // }}
            style={{marginLeft:'3%'}}
            >
            <SwiperSlide><FakeCard backGroundColor={color}/></SwiperSlide>
            <SwiperSlide><FakeCard backGroundColor={color}/></SwiperSlide>
            <SwiperSlide><FakeCard backGroundColor={color}/></SwiperSlide>
            <SwiperSlide><FakeCard backGroundColor={color}/></SwiperSlide>
            <SwiperSlide><FakeCard backGroundColor={color}/></SwiperSlide>
            <SwiperSlide><FakeCard backGroundColor={color}/></SwiperSlide>
        </Swiper>
        </>
    )
}