import Grid from "./Grid"
import "./WireFrame.css"
import { Swiper, SwiperSlide } from "swiper/react";
import GridCard from "./GridCard";
import { GetOrder2,GetRanking, GetWorry } from "../../firebase/GetData";
import {useEffect,useState} from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import FakeCards from "./FakeCard";
import { RightOutlined } from "@ant-design/icons";
import "swiper/css";
import "swiper/css/pagination";
import cat1 from '../../img/cat1.jpg'
import cat4 from '../../img/cat4.jpg'
import cat5 from '../../img/cat5.jpg'
// import { useSelector, useDispatch} from "react-redux";  
// import { useMediaQuery } from 'react-responsive'

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";	// 추가
SwiperCore.use([Navigation, Pagination, Autoplay])

function full(state){
    return state
  } 


export default function WireFrame({isMobile}){
    const navigate= useNavigate()
    const [Box,setBox]=useState([])
    const [Box2,setBox2]=useState([])
    const [worry,setWorry]=useState([])
    const [cnt,setCnt]=useState(false)
    const [cnt2,setCnt2]=useState(false)
    const [cnt3,setCnt3]=useState(false)

    let thingBox=[];
    let thingBox2=[];
    let worryBox=[];
    async function LoadReal(){
        if (!cnt){
            let thing = await GetOrder2(undefined,6)
            thing.forEach((doc)=>{
                let data=doc.data()
                thingBox = [...thingBox,<SwiperSlide key={data.createdAt.seconds}><GridCard data={data} backGroundColor={'#86AF49'} where={`./game/${data.createdAt.seconds}`}/></SwiperSlide>]
            })
            setBox(thingBox)
            setCnt(true)
        }
    }
    async function LoadHot(){
        if (!cnt2){
            let thing2 = await GetOrder2(undefined,6,false,'Hot')
            let i=0
            thing2.forEach((doc)=>{
            let data=doc.data()
            thingBox2 = [...thingBox2,<SwiperSlide key={data.createdAt.seconds}><GridCard data={data} where={`./BestGame/${data.createdAt.seconds}/${i}`}/></SwiperSlide>]
            i++
            })
            setBox2(thingBox2)
            setCnt2(true)
        }
    }
    async function LoadWorry(){
        if (!cnt2){
            let thing3 = await GetWorry(undefined,6)
            thing3.forEach((doc)=>{
            let data=doc.data()
            worryBox = [...worryBox,<SwiperSlide key={data.createdAt.seconds}><GridCard data={data} backGroundColor={'#F0DCDD'} where={`./Worry/${data.createdAt.seconds}`}/></SwiperSlide>]
            })
            setWorry(worryBox)
            setCnt3(true)
        }
    }
    useEffect(()=>{
        LoadHot()
        LoadReal()
        LoadWorry()
        // setBox([]);setBox2([]);setWorry([]);setCnt(false);setCnt2(false);setCnt3(false);
    },[])

    return(
        <>
            <Mascot isMobile={isMobile}/>

            

            <div onClick={()=>navigate('./game')} style={{cursor:'pointer',margin:'5% 0 0 0'}}>
                <span  className="Title">실시간 밸런스</span>
                <RightOutlined className="RightOutLine"/> 
            </div>
            {cnt ?( <Swiper
            spaceBetween={100}
            slidesPerView={isMobile ? (3):(5)}
            scrollbar={{ draggable: true }}
            pagination={{ clickable: true }}
            // breakpoints={{
            //     768: {
            //     slidesPerView: 7,
            //     },
            // }}
            style={{marginLeft:'3%'}}
            >
            {Box}
            </Swiper>):(<FakeCards color={'#86AF49'} isMobile={isMobile}></FakeCards>)}

            <div onClick={()=>navigate('./BestGame')} style={{cursor:'pointer'}}>
                <span  className="Title">주간 핫 밸런스</span>
                <RightOutlined className="RightOutLine"/> 
            </div>
            {cnt2 ? (<Swiper
            spaceBetween={100}
            slidesPerView={isMobile ? (3):(5)}
            scrollbar={{ draggable: true }}
            pagination={{ clickable: true, }}
            // breakpoints={{
            //     800: {
            //     slidesPerView: 5,
            //     },
            // }}
            style={{marginLeft:'3%'}}
            >
            {Box2}
            </Swiper>) : (<FakeCards isMobile={isMobile}></FakeCards>)}
            
            <h1 className="Title">챌린지</h1>
            <div style={isMobile ? {textAlign:''}:{margin:'0 0 0 20%'}}>
                <ChallengeGrid/>
                <ChallengeRanking isMobile={isMobile}/>
            </div>

            <div>
                <div  onClick={()=>navigate('./Worry')} style={{cursor:'pointer',margin:'5% 0 0 0'}}>
                    <span  className="Title">고민 게시판</span>
                    <RightOutlined className="RightOutLine"/>
                </div>
                {cnt3 ? (<Swiper
                spaceBetween={100}
                slidesPerView={isMobile ? (3):(5)}
                scrollbar={{ draggable: true }}
                pagination={{ clickable: true }}
                // breakpoints={{
                //     768: {
                //     slidesPerView: 7,
                //     },
                // }}
                style={{marginLeft:'3%'}}
                >
                {worry}
                </Swiper>):(<FakeCards color={'#F0DCDD'} isMobile={isMobile}></FakeCards>)}
            </div>

            
            <MakeGrid isMobile={isMobile}/>

            
            <OpinionGrid isMobile={isMobile}/>
        </>
    )
}
function Mascot({isMobile}){
    return(
        <div style={{textAlign:'center'}}>
            <div className={isMobile ? ('masTextM'):('masTextT')}>
                <p className="masText">Hello</p>
                <p className="masText">World!</p>
            </div>
            <img src={cat1} style={{width:'50%',margin:'0 0 0 10%'}}></img>
        </div>
    )
}

function ChallengeGrid(){

    return(
        <div>
            <Link to={'./challenge'} style={{ textDecoration: 'none',color:"black",float:'left'}}>
                <div id="challengeGrid" >
                    <h1 style={{textAlign:'center',marginTop:'40%',fontSize:'250%',fontFamily:"agroL",}}>시작하기</h1>
                </div>
            </Link>
        </div>
    )
}
function ChallengeRanking({isMobile}){
    const [cnt,setCnt]=useState(false)
    const [data,setData]=useState([])
    async function Load1(){
        const com = await GetRanking(isMobile ? (5):(5))
        let i=1
        let list=[]
        com.forEach((doc)=>{
            list=[...list,<Rank i={i} data={doc.data()} key={i} isMobile={isMobile}/>]
            i++
        })
        setData(list)
        setCnt(true)
    }
    useEffect(()=>{
        Load1()
    },[])
    return(
        <div>
            <div style={{border:'solid 3px #E0E4CC',marginLeft:'4%',borderRadius:'15px', display:'inline-block'}} className={isMobile ? ("rankingM"):("rankingT")}>
                <h1 style={{fontFamily:'agroM',margin:'5% 5%'}}>순위</h1>
                <div style={{width:'90%',margin:'0 auto',fontFamily:'agroL'}}>
                    {cnt ? (data):("")}
                </div>
            </div>
        </div>
    )
}

function Rank({i,data,isMobile}){

    return(
        <div className={isMobile ? ('rankM'):('rankT')}>
        <span style={{fontSize:'100%',fontWeight:'bold'}}>{i}위:</span>
        <span style={{fontSize:'100%'}}> {data.name} </span>
        {/* <span style={{fontSize:'90%'}}>  {data.point}점</span> */}
        </div>
    )
}

function MakeGrid({isMobile}){

    return(
        <div>
            <Link to={'./make'} style={{ textDecoration: 'none',color:"black",float:'left'}}>
            <h1 className="Title">만들기</h1>
                <div className={isMobile ? 'MakeGridM' : 'MakeGridT'}>
                    <div className={isMobile ? ('masTextM'):('masTextT')}>
                        <p className="masText">코냥이도</p>
                        <p className="masText">만드는 중....</p>
                    </div>
                    <img src={cat4} style={isMobile ? {width:'50%'}:{width:'30%'}}/>
                </div>
            </Link>
        </div>
    )
}
function OpinionGrid({isMobile}){

    return(
        <div>
            <Link to={'./opinion'} style={{ textDecoration: 'none',color:"black",float:'left'}}>
            <h1 className="Title">의견함</h1>
                <div className={isMobile ? 'MakeGridM' : 'MakeGridT'} >
                    <div className={isMobile ? ('masTextM'):('masTextT')}>
                        <p className="masText">의견을</p>
                        <p className="masText">주세요!</p>
                    </div>
                    <img src={cat5} style={isMobile ? {width:'50%'}:{width:'30%'}}/>
                </div>
            </Link>
        </div>
    )
}