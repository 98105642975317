import React, { useEffect, useState } from 'react';
import {  GetOrder,GetOrder2, GetWorry } from '../../firebase/GetData';
import './Slider.css'
import Game from './Game';
import {useParams,useNavigate} from "react-router-dom";
// import { useSelector, useDispatch} from "react-redux";
import { Swiper, SwiperSlide,useSwiperSlide,useSwiper } from "swiper/react";
import 'styled-components'
import "swiper/css/navigation";

export default function WorrySlider({isMobile}) {
  let {number1} = useParams()
  const navigate = useNavigate()
  const [games,setGames] = useState([])
  const [lID,setLID] = useState()
  const [cnt, setCnt]= useState(false)
  
  async function Load(){
    let thing = await GetWorry(number1,5,true)
    let thingBox=[];
    let i=0;
    let lastId=0;
    thing.forEach((doc)=>{
      let data=doc.data()
      thingBox=[...thingBox,<SwiperSlide key={data.createdAt.seconds}> <Game info={data} num={i} key={data.createdAt.seconds} isMobile={isMobile} backgroundColor={'#F0DCDD'} type={'worry'}/></SwiperSlide>]
      lastId =data.createdAt.seconds 
      i++
    })
    setLID(lastId)
    setGames(thingBox)
    setCnt(true)
}
function Next(){
  navigate(`/Worry/${lID -1}`)
}
  
  useEffect(()=>{
    setGames([])
    setLID()
    setCnt(false)
    Load()
  },[number1]);
  return (
    <>
    <div className={isMobile ? ('textBoxM'):('textBoxT')}>
    <p className='sliderText upText'>* 둘 중에 하나 클릭</p>
    <p className='sliderText'>* 옆으로 넘기기 ⇒⇒</p>
    </div>
    {cnt &&
    <Swiper
    spaceBetween={50}
    slidesPerView={1}
    scrollbar={{ draggable: true }}
    pagination={{ clickable: true }}
    onReachEnd={() => {Next()}}
    breakpoints={{
        768: {
        slidesPerView: 1,
        },
    
    }}
    // initialSlide={final ? (0):(1)}
    >
    {games}
    <SwiperSlide ></SwiperSlide>
    </Swiper>
    } 
    </>
  );
}