import BrandImg from "../../img/brandImg.png"
import { useMediaQuery } from 'react-responsive'

export default function Card({data,Click2,isClicked,backgroundColor}){
    const isMobile = useMediaQuery({ maxWidth: 500 })
    return(
        <>
            <div className={isMobile ? ('cardM card'):('cardT card')} style={{backgroundColor:backgroundColor}}>
                <div className="common">{data.common || data.id}</div>
                <div id="choice">
                    <div className="Select" onClick={()=>{Click2(data,1)}}>{data.select1}</div>
                    {isClicked ? (<ClickedNuber clicked={data.click1} last={data.click2}/>):("")}
                    <div className="vs">vs</div>
                    <div className="Select" onClick={()=>{Click2(data,2)}}>{data.select2}</div>
                    {isClicked ? (<ClickedNuber clicked={data.click2} last={data.click1}/>):("")}
                </div>
                {/* <img src={BrandImg} id={isMobile ? ('brandImgM'):('brandImgT')} alt="brandImg" ></img> */}
                <span id={isMobile ? ('brandImgM'):('brandImgT')}>언밸런스</span>
            </div>
        </>
    )
}

function ClickedNuber(props){
    let per = Math.round(props.clicked/(props.clicked+props.last)*100)
    if (isNaN(per)){
        per = 0
    }
    return(
        <>
        <div style={{textAlign:'center'}}>
            <span  style={{fontSize:'200%',fontFamily:'agroL'}}>{per+"%"}</span>
            <span style={{marginLeft:'5%',fontSize:'100%',fontFamily:'agroL'}}>({props.clicked}표)</span>
        </div>
        </>
    )
}