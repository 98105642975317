import "./Make.css";
import { MakeComment, MakeData, MakeOpinion, MakeWorry } from "../../firebase/GetData";
import { useState,useEffect,useRef } from "react";
import firebase from "firebase/compat/app";
import {useNavigate } from "react-router-dom";
import BrandImg from "../../img/brandImg.png"
import { useMediaQuery } from 'react-responsive'

export function Make() {
  const ref = useRef()
  const ref1 = useRef()
  const ref2 = useRef()
  const navigate = useNavigate();
  const [common,setCommon]=useState()
  const [select1,setSelect1]=useState()
  const [select2,setSelect2]=useState()
  const [userEmail,setUserEmail]=useState()
  const [isClick,setIsClick]=useState(true)
  const isMobile = useMediaQuery({ maxWidth: 500 })

  const onChange = (event) => {
    const {target: {name, value}} = event;
    if (name==='common') {
      setCommon(value)
    } else if (name=== "select1") {
      setSelect1(value)
    }else if (name === "select2"){
      setSelect2(value)
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if(select1 ===undefined || select2 === undefined){
      alert("선택지란을 채워주세요!")
      return
    }
    try {
      if(isClick){
        await MakeData(common,select1,select2,userEmail)
      }else{
        await MakeWorry(common,select1,select2,userEmail)
      }
      window.location.href="/make"
    } catch(error) {
      console.log(error)
      alert(error)
    }
  }

  const onResize = ()=>{
    const obj = ref.current;
    obj.style.height = 'auto'
    if(isClick){
      if (obj.scrollHeight <= 220){
        obj.style.height = obj.scrollHeight + 'px'
      }else{
        obj.style.height='220px'
      }
    }else{
      obj.style.height = obj.scrollHeight + 'px'
    }
  }
  const onResize1 = ()=>{
    const obj = ref1.current;
    obj.style.height = 'auto'
    if(isClick){
      if (obj.scrollHeight <= 220){
        obj.style.height = obj.scrollHeight + 'px'
      }else{
        obj.style.height='220px'
      }
    }else{
      obj.style.height = obj.scrollHeight + 'px'
    }
  }
  const onResize2 = ()=>{
    const obj = ref2.current;
    obj.style.height = 'auto'
    if(isClick){
      if (obj.scrollHeight <= 220){
        obj.style.height = obj.scrollHeight + 'px'
      }else{
        obj.style.height='220px'
      }
    }else{
      obj.style.height = obj.scrollHeight + 'px'
    }
  }
  const LoginCheck = ()=>{
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserEmail(user._delegate.email)
      }else{
          alert("로그인이 필요한 서비스입니다!")
          navigate('/login')
      }})
  }
  useEffect(()=>{
    LoginCheck()
  },[isClick])
  return (
    <>
    {isClick ? (
    <div id="infoDiv">
      <p>* 첫줄은 공통질문입니다. <span className="chong">총 17자 까지 입력 가능.</span></p>
      <p>* 두번째, 세번째 줄은 선택지입니다. <span className="chong">총 42자 까지 입력 가능.</span></p>
      <p>* 부적절한 단어는 임의로 삭제됩니다.</p>
    </div>
    ):(
      <div id="infoDiv">
        <p>* 첫줄은 공통질문입니다. <span className="chong">총 100자 까지 입력 가능.</span></p>
        <p>* 두번째, 세번째 줄은 선택지입니다. <span className="chong">총 19자 까지 입력 가능.</span></p>
        <p>* 부적절한 단어는 임의로 삭제됩니다.</p>
      </div>
    )}
      <div style={{textAlign:'center'}}>
        <button id="leftBut" style={{backgroundColor: isClick ? '#F38630' : 'white',}} onClick={()=>{if(!isClick){setIsClick(!isClick)}}}>일반 밸런스</button>
        <button id="rightBut" style={{backgroundColor: !isClick ? '#F38630' : 'white',}} onClick={()=>{if(isClick){setIsClick(!isClick)}}}>고민 밸런스</button>
      </div>
      {isClick ? (
      <>
        <form onSubmit={onSubmit}>
        <div className={isMobile ? ('cardM'):('cardT')}>
            <textarea ref={ref} name="common" className="makeCom" maxLength={17} rows="1" placeholder="Touch" required value={common} onChange={onChange} onKeyUp={onResize} onKeyDown={onResize}/>
            <textarea ref={ref1} name="select1" className="makeSel" maxLength={42} rows="1" placeholder="Touch" required value={select1} onChange={onChange} onKeyUp={onResize1} onKeyDown={onResize1}/>
            <div className="vs">vs</div>
            <textarea ref={ref2} name="select2" className="makeSel" maxLength={42} rows="1" placeholder="Touch" required value={select2} onChange={onChange} onKeyUp={onResize2} onKeyDown={onResize2}/>
            <span id={isMobile ? ('brandImgM'):('brandImgT')}>언밸런스</span>
        </div>
        <input type="submit" value={"🖌 만들기"} id="submitMake"/>     
        </form>
      </>
      ):(
        <form onSubmit={onSubmit}>
        <div className={isMobile ? ('cardM'):('cardT')}>
            <textarea ref={ref} name="common" className="makeCom makeComWorry" maxLength={100} rows="4" placeholder="Touch" required value={common} onChange={onChange} onKeyUp={onResize} onKeyDown={onResize}/>
            <textarea  name="select1" className="makeSel makeSelWorry" maxLength={9} rows="1" placeholder="Touch" required value={select1} onChange={onChange}/>
            <div className="vs vsWorry">vs</div>
            <textarea  name="select2" className="makeSel makeSelWorry" maxLength={9} rows="1" placeholder="Touch" required value={select2} onChange={onChange}/>
            <span id={isMobile ? ('brandImgM'):('brandImgT')}>언밸런스</span>
        </div>
        <input type="submit" value={"🖌 만들기"} id="submitMake"/>     
        </form>
      )}
    </>
  );
}





export function GameInputComment({ data,num,type }) {
  const [disabled, setDisabled] = useState(false);
  const [text, setText] = useState("");

  const onChange = (e) => {
    setText(e.target.value);
  };
  const beep= async function(event){
    event.preventDefault();
    setDisabled(true);
    let makeName = document.getElementById(`InputCommentName${num}`).value;
    let makeSelect1 = document.getElementById(`InputComment${num}`).value;
    await MakeComment(makeName,makeSelect1,data,type);
    window.location.reload();
  }
  return (
    <>
      <form onSubmit={beep} className="formComment">
        <div className="label">닉네임</div>
        <input type="text"
          name="textarea"
          className="InputCommentName"
          id={"InputCommentName"+num}
          placeholder="닉네임을 입력하세요"
          defaultValue="익명"
          maxLength='15'
          ></input>
        <div className="label">댓글 남기기</div>
        <textarea
          type="textarea"
          name="textarea"
          className="InputComment"
          id={"InputComment"+num}
          placeholder="댓글 작성하는 곳"
          onChange={onChange}
          value={text}
        ></textarea>
        <br></br>
        <button type="submit"
          disabled={disabled}
          className="submit">✏ Submit</button>
      </form>
    </>
  );
}

export function GameInputOpinion() {
  const [disabled, setDisabled] = useState(false);
  const [text, setText] = useState("");

  const onChange = (e) => {
    setText(e.target.value);
  };
  const beep= async function(event){
    event.preventDefault();
    setDisabled(true);
    let makeName = document.getElementById('InputOpinionName').value;
    let makeSelect1 = document.getElementById('InputOpinion').value;
    await MakeOpinion(makeName,makeSelect1);
    window.location.reload();
  }
  return (
    <>
      <form onSubmit={beep} className="formComment">
        <div className="label">닉네임</div>
        <input type="text"
          name="textarea"
          className="InputCommentName"
          id="InputOpinionName"
          placeholder="닉네임을 입력하세요"
          defaultValue="익명"
          maxLength='15'
          ></input>
        <div className="label">댓글 남기기</div>
        <textarea
          type="textarea"
          name="textarea"
          className="InputComment"
          id="InputOpinion"
          placeholder="댓글 작성하는 곳"
          onChange={onChange}
          value={text}
        ></textarea>
        <br></br>
        <input type="submit"
          disabled={disabled}
          className="submit"
          value={"✏ Submit"}></input>
        {/* <button type="submit"
        disabled={disabled}
        className="submit"
        >✏ Submit</button> */}
      </form>
    </>
  );
}