import "./Game.css"
import {LikeOutlined,LikeFilled, DoubleRightOutlined, DoubleLeftOutlined} from '@ant-design/icons';
import {useState,useEffect,useRef} from 'react'
import { SetClick,GetComment,SetLike,SetBadReport, SetBadCommentReport,GetLast, } from "../../firebase/GetData";
import { GameInputComment } from "../Make/Make";
import Card from "./Card";
import html2canvas from "html2canvas";
import { useSwiper } from 'swiper/react';
import { now } from "../Grid/GridCard";

export default function Game({info,num,backgroundColor,type}) {
    const swiper = useSwiper();
    const myRef = useRef()
    const [data, setData] = useState({});
    const [isClicked, setIsClicked] = useState(false);
    // const [isLiked, setIsLiked] = useState(false);
    const [isReported, setIsReported] = useState(false);
    const [comment, setComment] = useState([]);
    const [cnt,setCnt]=useState(false)
    const [size,setSize]=useState(0)
    
    function boo(data1) {
        setData(data1);
    }
    
    async function Click2(data,what){
        if (isClicked === false){
            await SetClick(data, what, type);
            console.log(type)
            setIsClicked(true);
        }
    }
    // async function Like(id){
    //     await SetLike(id)
    //     setIsLiked(true)
               
    // }
    async function Report(data){
        if(isReported===false){
            await SetBadReport(data)
            setIsReported(true)
            alert("📣 신고가 완료되었습니다.")
        }else{
            alert("🧨 이미 신고되었습니다!")
        }
    }

    async function Save(){
        const canvas = await html2canvas(document.getElementsByClassName('card')[num]);
        const image =  canvas.toDataURL("image/png");
        const link = document.createElement('a')
        document.body.appendChild(link)
        link.download = "card"
        link.href=image
        link.click()
        document.body.removeChild(link)
    }
    
    const Comment = async()=>{
        const raw = await GetComment(info,type)
        let list1=[]
        let i =0
        raw.forEach((that,index)=>{
            let data = that.data();
            list1 =[...list1,<Tweet data={data} key={i}/>]
            i++
        })
        setSize(i)
        setComment(list1)
        setCnt(true)
    }

    
    useEffect(()=>{
        boo(info);
        Comment()
        setIsClicked(false);
        // setIsLiked(false);
        setIsReported(false);
        setCnt(false)
    },[info])
    return(
        
        <div className="ground">
            <Card data={data} Click2={Click2} isClicked={isClicked} backgroundColor={backgroundColor}/>
            <div id ="timeInfo1">{cnt && now(data)} <span id ="timeInfo2">({cnt && Time2Pretty(data.createdAt)})</span></div>
            <div id ="downInfo">
                {/* <div className="LikeCover">
                    {isLiked ? (<Animated animationIn="bounce"  animationInDuration={700}><LikeFilled style={{ color: 'black', fontSize: '25px'}}/></Animated>):(<LikeOutlined style={{ color: 'black', fontSize: '25px'}} onClick={()=>Like(data.id)} disabled={isLiked}/>)}
                </div> */}
                {/* {cnt && data.like} */}
                <div id='view'>👀 {data.totalClick}</div>
                <div id="cardComment" onClick={()=>myRef.current.scrollIntoView()} >💬 {cnt && size}</div>
                <div id="singo" onClick={() => Report(data)}>🚫신고</div>
                <div id="save"onClick={()=>Save()}>📷사진 저장</div>
            </div>
            <div style={{textAlign:'center'}}>
                <DoubleLeftOutlined style={{fontSize:'5vh'}} onClick={()=>{swiper.slidePrev()}}/>
                <DoubleRightOutlined style={{fontSize:'5vh',marginLeft:'78%'}} onClick={()=>{swiper.slideNext()}}/>
            </div>
            <div ref={myRef} className="commentWindow">
                <div className="marker">
                    <GameInputComment data={data} num={num} type={type}/>
                </div>
                <div className="board">{cnt && comment}</div>
            </div>
        </div>
        
    )
}
function Time2Pretty(times){
    if (times){
        let childData = times.toDate();
        childData = new Date(+childData + 3240 * 10000)
                    .toISOString()
                    .replace("T", " ")
                    .replace(/\..*/, "");
        return childData
    }
}

function Tweet({data}){
    const [report,setReport]=useState(false)
    let childData = Time2Pretty(data.timestamp)
    
    async function singo(data){
        if(report ===false){
            setReport(true)
            await SetBadCommentReport(data)
            alert("📢 신고가 완료되었습니다!")
        }else{
            alert("🧨 이미 신고되었습니다!")
        }
    }
    return(
        <div>
            <div className='comment'>
                <div style={{display:'inline',fontWeight:'bold'}}>{data.name ? (data.name):("익명")}</div>
                <div style={{display:'inline'}}>
                    <span id='date'>{childData}</span>
                    <p id="singoComment" onClick={()=>{singo(data)}}>신고</p>
                </div>
                <div>{data.word}</div>
            </div>
        </div>
    )
}


