import {
    Nav,
    Navbar,
    Container,
    // NavDropdown,
    // Form,
    // FormControl,
    Button,
    Offcanvas,
  } from "react-bootstrap";
  import "bootstrap/dist/css/bootstrap.min.css";
  import "./Navigation.css";
  import BrandImg from "../img/brandImg.png"
  import { useState,useEffect } from "react";
  import { auth } from "../firebase/firebase_config";
  import {useNavigate } from "react-router-dom";
  import {UserOutlined} from '@ant-design/icons';
//   import facebook from "../img/facebook.png"
//   import naver from "../img/naver.png"
// import kakao from "../img/kakao.png"
  import google from "../img/google.png"
  import { useMediaQuery } from 'react-responsive'
import GoBack from "./GoBack";
import cat3 from '../img/cat3.jpg'
import html2canvas from "html2canvas";

export default function Navigation() {
    const [isLogIn, setIsLogIn]=useState(false)
    const [user,setUser]=useState({})
    const isMobile = useMediaQuery({ maxWidth: 500 })
    const navigate = useNavigate()
    const LoginCheck = ()=>{
      auth.onAuthStateChanged((user) => {
        if (user) {
          setIsLogIn(true)
          setUser(user._delegate)
        }else{
            // alert("로그인이 필요한 서비스입니다!")
        }})
    }

    useEffect(()=>{
      LoginCheck()
    },[isLogIn])
    return (  
        <>
        {[false].map((expand) => (
            <Navbar key={expand} bg="light" expand={expand} className="mb-3">
            <Container fluid>
                {isMobile ? (''):(<GoBack/>)}
                {/* <Navbar.Brand href="/" className={isMobile ? (""):("BrandT")}><img src={BrandImg} width="70px" alt="brandImg" /></Navbar.Brand> */}
                <Navbar.Brand href="/" className={isMobile ? (""):("BrandT")}><span id='logo1'>언밸런스</span></Navbar.Brand>
                <Nav className={isMobile ? ('MakeM me-auto'):('MakeT me-auto')}>
                    <Nav.Link onClick={()=>{navigate('/make')}} >🎮 만들기</Nav.Link>
                </Nav>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                style={{width:'60%'}}
                >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} style={{fontFamily:'agroL',fontSize:'150%'}}>
                        메뉴
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {/* <Form className="d-flex">
                        <FormControl
                            type="search"
                            placeholder="Search"
                            className="me-2"
                            aria-label="Search"
                        />
                    <Button variant="outline-success">Search</Button>
                    </Form> */}
                    <Nav className="justify-content-end flex-grow-1 pe-3" style={{fontFamily:'agroL'}}>
                        <Nav.Link onClick={()=>{navigate('/')}}>🏠 Home</Nav.Link>
                        <Nav.Link onClick={()=>{navigate('/make')}}>🎮 만들기</Nav.Link>
                        <Nav.Link onClick={()=>{navigate('/challenge')}}>🚩 챌린지</Nav.Link>
                        <Nav.Link onClick={()=>{navigate('/opinion')}}>📫 의견을 남겨주세요</Nav.Link>
                        <Nav.Link onClick={()=>{navigate('/producer')}}>👨‍🔬 제작자</Nav.Link>
                        {/* <NavDropdown
                            title="Language"
                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                        >
                            <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action4">
                            Another action
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action5">
                            Something else here
                            </NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                    {isLogIn ? (
                        <>
                        <div id="login" className="center">
                            {/* <UserOutlined style={{ fontSize: '150px', color: 'black', display:'block'}}/> */}
                            <img src={cat3} style={isMobile ? {width:'80%'}:{width:'40%'}}></img>
                            <span id="email">{user.email}</span>
                            <Button variant="outline-dark" href="/" onClick={()=>{
                                auth.signOut()
                                alert("로그아웃 됐습니다!")
                                }}>
                                로그아웃
                            </Button>
                        </div> 
                        </>
                    ):(
                        <>
                        <Nav.Link href="/login">
                        <div id="login" className="center" >
                            <h1>로그인</h1>
                            <UserOutlined style={{ fontSize: '150px', color: 'black', display:'block'}}/>
                            <img src={google} alt="google" className="logo"/>
                            {/* <img src={kakao} alt="kakao" className="logo"/>
                            <img src={naver} alt="naver" className="logo"/>
                            <img src={facebook} alt="facebook" className="logo"/> */}
                        </div>
                        </Nav.Link>
                        </>
                    )}
                </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
            </Navbar>
        ))}
        </>
    );
}