import BrandImg from "../../img/brandImg.png"
import "./GridCard.css"
import { useNavigate } from "react-router-dom";
export default function GridCard({data,backGroundColor,display,margin,where,time}){
    const navigate= useNavigate()
    
    return(
        <div style={{float:'left',margin:margin}} onClick={()=>{navigate(where)}}>
            <div className="GridCard" style={{backgroundColor:backGroundColor,display:display}}>
                <div className="Gridcommon">{data.common || data.id}</div>
                <div id="Gridchoice">
                    <div className="GridSelect">{data.select1}</div>
                    <div className="Gridvs">vs</div>
                    <div className="GridSelect" >{data.select2}</div>
                </div>
            </div>
            <div style={{height:'40px',width:'138px',textAlign:'center'}}>
                <div id="GridTime">{(time) ? (now(data)) :("")} </div>
                <div style={{display:'inline'}}>
                    <span id="GridLike">👀{data.totalClick}</span>
                    <span id="GridComment"> 💬</span>
                    <span style={{fontFamily:'agroL'}}>{data.commentSize}</span>
                </div>
            </div>
        </div>
    )
}

export function now(data){
    const nowSecond = (new Date()-data.createdAt.toDate())/1000
    const nowMinute = nowSecond/60
    const nowHour = nowMinute/60
    const nowDay = nowHour/24
    const nowMonth = nowDay/30
    const nowYear = nowMonth/12
    if (0<nowSecond && nowSecond <60){
        return  Math.floor(nowSecond)+"초 전"
    }else if(1<= nowMinute && nowMinute<60){
        return  Math.floor(nowMinute) +"분 전"
    }else if(1<= nowHour && nowHour <24){
        return  Math.floor(nowHour) +"시간 전"
    }else if(1<= nowDay && nowDay <31){
        return Math.floor(nowDay) +"일 전"
    }else if(1<= nowMonth && nowMonth <12){
        return Math.floor(nowMonth) +"달 전"
    }else{
        return Math.floor(nowYear) +"년 전"
    }
} 