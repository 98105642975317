import React, { useEffect, useState } from 'react';
import {  GetOrder,GetOrder2 } from '../../firebase/GetData';
import './Slider.css'
import Game from './Game';
import {useParams,useNavigate} from "react-router-dom";
import { Swiper, SwiperSlide,useSwiperSlide,useSwiper } from "swiper/react";
import 'styled-components'
import "swiper/css/navigation";
import Card from './Card';

export default function BestSlider({isMobile}) {
  let {number1,number2} = useParams()
  const [games,setGames] = useState([])
  const [lID,setLID] = useState()
  const [cnt, setCnt]= useState(false)
  
  async function Load(){
    let thing = await GetOrder2(number1,12,false,'Hot')
    let thingBox=[];
    let i=0;
    let j= number2;
    let lastId=0;
    thing.forEach((doc)=>{
      if (j<1){
        let data=doc.data()
        thingBox=[...thingBox,<SwiperSlide key={i}> <Game info={data} num={i} key={data.createdAt.seconds} isMobile={isMobile} /></SwiperSlide>]
        lastId =data.createdAt.seconds 
        i++
      }
      j--
    })
    setLID(lastId)
    setGames(thingBox)
    setCnt(true)
}
  
  useEffect(()=>{
    Load()
    setGames([]);setLID();setCnt(false);
  },[number1]);
  return (
    <>
    <div className={isMobile ? ('textBoxM'):('textBoxT')}>
    <p className='sliderText upText'>* 둘 중에 하나 클릭</p>
    <p className='sliderText'>* 옆으로 넘기기 ⇒⇒</p>
    </div>
    {cnt &&
    <Swiper
    spaceBetween={50}
    slidesPerView={1}
    scrollbar={{ draggable: true }}
    pagination={{ clickable: true }}
    breakpoints={{
        768: {
        slidesPerView: 1,
        },
    
    }}
    // initialSlide={final ? (0):(1)}
    >
    {games}
    {/* {cnt && <SwiperSlide ><SlideTitle nextId={lID} direction={0}/></SwiperSlide>} */}
    <SwiperSlide ><LastCard/></SwiperSlide>
    </Swiper>
    } 
    </>
  );
}

function LastCard(){
  const navigate = useNavigate()
  let data={
    common:'마지막입니다. 다음주에 또 만나요!😉',
    select1:'실시간 밸런스로 이동',
    select2:'홈으로 이동',
  }
  function Click2(data,num){
    if(num===1){
      navigate('/game')
    }else if(num==2){
      navigate('/')
    }
  }
  return(
    <>
      <Card data={data} Click2={Click2} isClicked={false}/>
    </>
  )

}